// Initializes material JavaScript components.
//
const initMaterialize = () => {
    // Tabs
    const tabs = document.querySelectorAll('.tabs');
    for (let i = 0; i < tabs.length; i++) {
        M.Tabs.init(tabs[i]);
    }

    // Tooltips
    const tooltips = document.querySelectorAll('.tooltipped');
    for (let i = 0; i < tooltips.length; i++) {
        M.Tooltip.init(tooltips[i]);
    }

    // Sidenav
    const sidenavs = document.querySelectorAll('.sidenav');
    for (let i = 0; i < sidenavs.length; i++) {
        M.Sidenav.init(sidenavs[i]);
    }
};

// Initializes drag and drop sorting.
//
const initHtml5Sortable = (sortable, Rails) => {
    const sortingElements = document.querySelectorAll('.js_sortable-table');
    if (sortingElements === null) {
        return;
    }

    sortable(sortingElements, {
        forcePlaceholderSize: true,
        items: 'tr',
        placeholder: "<tr class=\"grey darken-2\"><td colspan=\"6\"><span class=\"center\">The row will appear here</span></td></tr>"
    });

    // Add event listener to each table element.
    for (let i = 0; i < sortingElements.length; i++){
        sortable(sortingElements)[i].addEventListener('sortupdate', function (e) {
            let dataIDList = [];
            let sortedChildren = e.target.querySelectorAll('tr');

            for (let i = 0; i < sortedChildren.length; i++){
                // Update position in table view.
                sortedChildren[i].querySelector('.js_sortable-position').textContent = (i + 1).toString();
                // Store id for server submission.
                dataIDList.push("sorted_sound_set[]=" + sortedChildren[i].getAttribute('data-id'));
            }

            dataIDList = dataIDList.join("&"); // => {"sorted_sound_set"=>["1", "3", "4", "2", "5"]}

            Rails.ajax({
                url: e.target.getAttribute('data-url'),
                type: "PATCH",
                data: dataIDList // JSON.stringify({'abc': dataIDList})
            });
        });
    }
};

// Update URL to match selected tab (sound sets).
// Also update the link to the new sound form to redirect to tabs.
//
const updateTabUrl = () => {
    let tabs = document.querySelectorAll('.tabs .tab a');

    if (!tabs.length) {
        return;
    }

    for (let i = 0; i < tabs.length; i++) {
        let ele = tabs[i];

        ele.onclick = function(event) {
            let href = event.target.getAttribute('href');
            if (!href) {return;}

            let substr = href.substring(1);
            window.history.pushState(substr, 'tab', '?tab=' + substr);
            document.getElementsByName('tab')[0].value = substr;

            return false;
        };
    }
};

export {
    initMaterialize,
    initHtml5Sortable,
    updateTabUrl
};