const qrcode = require('qrcode-generator/qrcode');

// Prepares the QR-code.
//
const generateQR = () => {
    const placeholder = document.getElementById('js_qrPlaceholder');
    if (placeholder === null) {
        return;
    }

    const uuid = placeholder.getAttribute('data-uuid');
    // QR relevant variables.
    const typeNumber = 0;
    const errorCorrectionLevel = 'L';
    const qr = qrcode(typeNumber, errorCorrectionLevel);

    qr.addData(uuid);
    qr.make();
    placeholder.innerHTML = qr.createImgTag(8, 4);
};

export { generateQR };